import React from 'react'
import { string } from 'prop-types'
import { Helmet } from 'react-helmet'

const propTypes = {
  description: string,
  image: string,
  siteName: string,
  title: string,
  url: string,
}

const Twitter = ({ description, image, siteName, title, url }) => (
  <Helmet>
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:url" content={url} />
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:creator" content={siteName} />
    <meta name="twitter:image" content={image} />
  </Helmet>
)

Twitter.propTypes = propTypes

export { Twitter }
