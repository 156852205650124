import { css, styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

const maxWidthContainer = '1224px'
const containerPadding = '40px'

export const Container = styled('div')(
  ({ noPadding, theme }) => css`
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    max-width: ${noPadding
      ? maxWidthContainer
      : `calc(${maxWidthContainer} + ${containerPadding})`};
    padding-left: ${noPadding ? 0 : selectTheme('spacingLeft.3xl')({ theme })};
    padding-right: ${noPadding
      ? 0
      : selectTheme('spacingRight.3xl')({ theme })};
    width: 100%;

    ${media.up('xs')} {
      padding-left: ${noPadding
        ? 0
        : selectTheme('spacingLeft.5xl')({ theme })};
      padding-right: ${noPadding
        ? 0
        : selectTheme('spacingRight.5xl')({ theme })};
    }
  `,
)
