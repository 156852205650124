/* eslint-disable react/jsx-no-literals*/
import { array, bool, number, string } from 'prop-types'

import { useTriggerEvents } from 'hooks/useTriggerEvents'
import chev from 'images/chevron.svg'
import greenFriday from 'images/green-friday.png'

import { Container } from 'components/Container'

import { Logo, RightIcon, StyledGreenFridayBanner, Text } from './styles'

const propTypes = {
  currentPath: string,
  customBannerTextByPath: array,
  ignoreBannerPaths: array,
  sectionIndex: number,
  shouldRender: bool,
}

export const GreenFridayBanner = props => {
  const {
    currentPath,
    customBannerTextByPath,
    ignoreBannerPaths = [],
    sectionIndex,
    shouldRender = false,
  } = props

  const { triggerEvent } = useTriggerEvents()

  const customText = customBannerTextByPath?.find(
    item => item?.path === currentPath,
  )
  const bannerText = Boolean(customText && customText.text)
    ? customText?.text
    : 'Green Friday Creditas: <br />Primeira parcela grátis e outras ofertas imperdíveis'

  const dispatchEvent = () => {
    triggerEvent({
      label: bannerText,
      pageInteraction: false,
      action: 'Banner | Green Friday',
    })
  }

  if (
    !shouldRender ||
    sectionIndex > 0 ||
    ignoreBannerPaths?.includes(currentPath)
  )
    return null

  return (
    <Container as="section" role="region">
      <StyledGreenFridayBanner onClick={dispatchEvent} href="/greenfriday">
        <Logo src={greenFriday} />
        <Text variant={{ _: 'bodyXsRegular', '5xl': 'bodySmRegular' }}>
          {bannerText}
        </Text>
        <RightIcon src={chev} />
      </StyledGreenFridayBanner>
    </Container>
  )
}

GreenFridayBanner.propTypes = propTypes
