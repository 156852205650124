import React from 'react'

const AppStore = ({ ...others }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...others}
  >
    <path
      d="M13.7907 9.03177C13.7991 8.3808 13.972 7.74253 14.2934 7.17634C14.6147 6.61015 15.0741 6.13445 15.6287 5.79349C15.2764 5.29031 14.8116 4.87622 14.2712 4.5841C13.7309 4.29198 13.1298 4.12988 12.5159 4.11067C11.2062 3.97319 9.93654 4.89436 9.26914 4.89436C8.58883 4.89436 7.56129 4.12432 6.45478 4.14708C5.73907 4.1702 5.04155 4.37833 4.43019 4.75118C3.81882 5.12402 3.31447 5.64888 2.96626 6.27461C1.45792 8.88611 2.58301 12.7241 4.02789 14.8351C4.7508 15.8688 5.59567 17.0234 6.70117 16.9825C7.78297 16.9376 8.18701 16.2926 9.49282 16.2926C10.7865 16.2926 11.1656 16.9825 12.2935 16.9564C13.4544 16.9376 14.1857 15.9182 14.8833 14.8747C15.4027 14.1382 15.8024 13.3242 16.0675 12.4628C15.3931 12.1776 14.8176 11.7001 14.4127 11.09C14.0078 10.4798 13.7915 9.76402 13.7907 9.03177Z"
      fill="#1F2D27"
    />
    <path
      d="M11.661 2.72242C12.2939 1.96261 12.6057 0.986006 12.5302 0C11.5632 0.101561 10.67 0.56371 10.0285 1.29436C9.71491 1.65131 9.47469 2.06657 9.32163 2.5164C9.16858 2.96624 9.10568 3.44183 9.13654 3.91599C9.6202 3.92097 10.0987 3.81614 10.536 3.60939C10.9732 3.40264 11.3579 3.09937 11.661 2.72242Z"
      fill="#1F2D27"
    />
  </svg>
)

export { AppStore }
