import { styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import RichText from 'components/RichText'

import { LazyImage } from '../LazyImage'

export const StyledGreenFridayBanner = styled.a`
  background: ${selectTheme('colors.neutral.90')};
  color: ${selectTheme('colors.neutral.0')};
  padding: 24px;
  margin: 32px auto;
  max-width: 600px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-decoration: none;
  transition: transform 0.2s ease-in-out;

  ${media.up('5xl')} {
    margin: 64px auto;
  }
`

export const Text = styled(RichText)`
  width: 100%;
`

export const RightIcon = styled(LazyImage)`
  display: block;
  min-width: 6px;
  height: 10px;
  margin-left: 34px;

  ${media.up('5xl')} {
    margin-left: 26px;
  }
`

export const Logo = styled(LazyImage)`
  display: block;
  min-width: 78px;
  height: 40px;
  margin-right: 16px;
  align-self: flex-start;

  ${media.up('5xl')} {
    margin-right: 24px;
  }
`
