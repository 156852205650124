import { css, styled } from '@creditas-ui/system'
import { getTypography, media, selectTheme } from '@creditas-ui/utilities'

export const Wrapper = styled.li`
  ${getTypography('bodySmMedium')}
  display: flex;
  align-items: center;
  color: ${selectTheme('colors.secondary.40')};

  ${media.up('5xl')} {
    ${getTypography('bodySmRegular')}
    color: ${selectTheme('colors.neutral.40')};
  }

  &:not(:nth-last-of-type(2)) {
    ${media.down('4xl')} {
      display: none;
    }
  }

  &:last-of-type {
    color: ${selectTheme('colors.secondary.40')};
    text-decoration: none;

    ${media.up('5xl')} {
      ${getTypography('bodySmMedium')}
    }
  }

  svg {
    display: block;
    width: 24px;
    height: 24px;
    margin: 0 8px;

    ${media.down('4xl')} {
      margin-left: 0;
      margin-right: 4px;
      transform: rotate(180deg);
    }
  }
`

export const Content = styled.a(
  ({ isLast }) => css`
    display: block;
    color: inherit;
    cursor: ${isLast ? 'default' : 'pointer'};
    text-decoration: none;

    &:hover {
      text-decoration: ${isLast ? 'none' : 'underline'};
    }
  `,
)
