import loadable from '@loadable/component'

const Sections = {
  HeroInfluencer: loadable(() => import('sections/HeroInfluencer')),
  HeroWithSlider: loadable(() => import('sections/HeroWithSlider')),
  HeroImage: loadable(() => import('sections/HeroImage')),
  ProductComparison: loadable(() => import('sections/ProductComparison')),
  Exponencial: loadable(() => import('sections/Exponencial')),
  SocialProof: loadable(() => import('sections/SocialProof')),
  WhyChooseUsChart: loadable(() => import('sections/WhyChooseUsChart')),
  HowItWorks: loadable(() => import('sections/HowItWorks')),
  RelatedLinks: loadable(() => import('sections/RelatedLinks')),
  FullWidthImage: loadable(() =>
    import('sections/FullWidthImage/FullWidthImage'),
  ),
  GenericImage: loadable(() => import('sections/GenericImage')),
  GenericVideo: loadable(() => import('sections/GenericVideo')),
  CrossSell: loadable(() => import('sections/CrossSell')),
  HeroForm: loadable(() => import('sections/HeroForm')),
  HeroFormWithImage: loadable(() => import('sections/HeroFormWithImage')),
  HeroProducts: loadable(() => import('sections/HeroProducts')),
  HeroStepper: loadable(() => import('sections/HeroStepper')),
  MediaLogos: loadable(() => import('sections/MediaLogos')),
  WhyTrustUs: loadable(() => import('sections/WhyTrustUs')),
  PureText: loadable(() => import('sections/PureText/PureText')),
  CpfChecker: loadable(() => import('sections/CpfChecker')),
  Faq: loadable(() => import('sections/Faq')),
  GenericSlides: loadable(() => import('sections/GenericSlides')),
  Qa: loadable(() => import('sections/Qa')),
  PersonalizedService: loadable(() => import('sections/PersonalizedService')),
  CarouselImageVideo: loadable(() => import('sections/CarouselImageVideo')),
  ExponencialBanner: loadable(() => import('sections/ExponencialBanner')),
  Spacing: loadable(() => import('sections/Spacing')),
  Benefits: loadable(() => import('sections/Benefits')),
  HighlightImage: loadable(() => import('sections/HighlightImage')),
  HeroHighlightImageWithForm: loadable(() =>
    import('sections/HeroHighlightImageWithForm'),
  ),
}

export default Sections
