import React, { useState } from 'react'
import { node } from 'prop-types'

const HeaderContext = React.createContext({})

const propTypes = {
  children: node,
}

const HeaderProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <HeaderContext.Provider value={{ isOpen, setIsOpen }}>
      {children}
    </HeaderContext.Provider>
  )
}

HeaderProvider.propTypes = propTypes

export { HeaderContext, HeaderProvider }
