import React, { useContext, useEffect } from 'react'
import { node, object, string } from 'prop-types'
import Helmet from 'react-helmet'

import { Global } from '@creditas/global'
import { ThemeProvider as LegacyThemeProvider } from '@creditas/stylitas'
import tokens from '@creditas/tokens'
import newTokens from '@creditas/tokens/dist/new-version'
import { SimulatorProvider } from '@creditas-lab/simulator'
import globalTokens from '@creditas-lab/tokens'
import { ThemeProvider } from '@creditas-ui/system'
import { standard } from '@creditas-ui/themes'
import { legacy } from 'ui/themes/legacy'
import { typographyColors, typographyFonts } from 'ui/utils/typographyTokens'

import { HeaderContext, HeaderProvider } from 'contexts/HeaderContext'

import { Footer } from 'components/Footer'
import { Header } from 'components/Header'
import { Metatags } from 'components/Metatags'

import { customReset, Main } from './layout.style'

const propTypes = {
  children: node.isRequired,
  experiment: string,
  header: string,
  metatags: object,
}

const defaultProps = {
  header: 'creditas',
}

const HeaderWrapper = ({ headerType }) => {
  const { isOpen, setIsOpen } = useContext(HeaderContext)

  return (
    <>
      <Helmet
        htmlAttributes={{
          class: isOpen ? 'header-menu-open' : '',
        }}
      />

      <Header type={headerType} setIsOpen={setIsOpen} />
    </>
  )
}

const Layout = ({ children, experiment, header, metatags }) => {
  const headerType = header || defaultProps.header

  useEffect(() => {
    const module =
      typeof window === `undefined` ? null : require('@creditas/dream-catcher')

    if (module) {
      new module.DreamCatcher().init()
    }
  }, [])

  return (
    <LegacyThemeProvider
      theme={{
        ...tokens,
        ...newTokens,
        ...typographyColors,
        ...typographyFonts,
        ...globalTokens,
      }}
    >
      <ThemeProvider
        theme={{
          ...standard,
          ...legacy,
        }}
      >
        <SimulatorProvider
          interestRateType={experiment === 'CRMGT-005b' ? 'AVERAGE' : 'MINIMUM'}
        >
          <Global custom={customReset.styles} />
          {metatags && <Metatags data={metatags} />}
          <HeaderProvider>
            <HeaderWrapper headerType={headerType} />
            <Main headerType={headerType}>{children}</Main>
          </HeaderProvider>
          <Footer />
        </SimulatorProvider>
      </ThemeProvider>
    </LegacyThemeProvider>
  )
}

Layout.propTypes = propTypes
Layout.defaultProps = defaultProps
Layout.displayName = 'Layout'

HeaderWrapper.propTypes = {
  headerType: string,
}

export { Layout }
