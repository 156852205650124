import { arrayOf, shape, string } from 'prop-types'

import Item from './components/Item'

import { Container, List } from './styles'

const propTypes = {
  levels: arrayOf(
    shape({
      id: string,
      href: string,
      label: string,
    }),
  ),
}

const Breadcrumb = ({ levels }) => {
  if (!levels?.length) return null

  return (
    <Container>
      <List
        itemScope
        itemType="https://schema.org/BreadcrumbList"
        data-testid="breadcrumb"
      >
        {levels.map(({ href, id, label }, index) => (
          <Item key={id} href={href} index={++index} totalItems={levels.length}>
            {label}
          </Item>
        ))}
      </List>
    </Container>
  )
}

Breadcrumb.propTypes = propTypes

export { Breadcrumb }
