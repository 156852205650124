import { number, string } from 'prop-types'

import { ChevronRight } from 'components/Icons'

import { Content, Wrapper } from './styles'

const propTypes = {
  children: string,
  href: string,
  index: number,
  totalItems: number,
}

const Item = ({ children, href, index, totalItems }) => {
  if (!children) return null

  const isFirst = index === 1
  const isLast = index === totalItems

  const handleClick = event => {
    if (isLast) event.preventDefault()
  }

  return (
    <Wrapper
      itemProp="itemListElement"
      itemScope
      itemType="https://schema.org/ListItem"
    >
      {!isFirst && <ChevronRight />}

      <Content
        itemProp="item"
        isLast={isLast}
        href={`${process.env.PORTAL_DOMAIN_NAME}${href}`}
        onClick={handleClick}
      >
        <span itemProp="name">{children}</span>
      </Content>

      <meta itemProp="position" content={index} />
    </Wrapper>
  )
}

Item.propTypes = propTypes

export default Item
