import React from 'react'

const CreditasLogo = ({ ...others }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 12"
    {...others}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 7.663c0 .16-.051.283-.051.283-.84 2.315-3.04 3.87-5.477 3.87-.825 0-1.61-.175-2.322-.49 0 0-1.007.49-2.318.49C2.612 11.816 0 9.17 0 5.908 0 2.645 2.611 0 5.832 0 7.172 0 8.15.49 8.15.49A5.736 5.736 0 0110.472 0c2.437 0 4.636 1.555 5.477 3.87 0 0 .051.122.051.283a.628.628 0 01-.11.364.811.811 0 01-.67.33h-3.806c-.633 0-.894-.74-.894-.74A5.063 5.063 0 008.151 1.41 5.09 5.09 0 005.46 5.908a5.09 5.09 0 002.691 4.497 5.063 5.063 0 002.37-2.696s.26-.74.893-.74h3.806a.811.811 0 01.67.33c.085.122.11.252.11.364zm-4.733-4.044A5.931 5.931 0 009.142.995a4.978 4.978 0 011.374-.194c2.06 0 3.924 1.282 4.688 3.205h-3.513c-.276 0-.424-.387-.424-.387zM.797 5.86C.796 3.068 3.04.8 5.798.8c.466 0 .922.067 1.357.192a5.904 5.904 0 00-2.55 4.865 5.905 5.905 0 002.55 4.865 4.916 4.916 0 01-1.357.192C3.04 10.915.797 8.647.796 5.858zm8.345 4.962a5.932 5.932 0 002.125-2.624s.148-.386.424-.386h3.513c-.764 1.923-2.628 3.204-4.688 3.204-.476 0-.937-.069-1.374-.194z"
      fill="#1B7"
    />
  </svg>
)

export { CreditasLogo }
