import React from 'react'
import { string } from 'prop-types'
import { Helmet } from 'react-helmet'

const propTypes = {
  description: string,
  image: string,
  siteName: string,
  title: string,
  url: string,
}

const Facebook = ({ description, image, siteName, title, url }) => (
  <Helmet>
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:type" content="website" />
    <meta property="og:site_name" content={siteName} />
    <meta property="og:url" content={url} />
    <meta property="og:locale" content="pt_BR" />
    <meta property="og:image" content={image} />
    <meta property="og:image:type" content="image/jpg" />
    <meta property="og:image:width" content="1200" />
    <meta property="og:image:height" content="628" />
  </Helmet>
)

Facebook.propTypes = propTypes

export { Facebook }
