import BProductCarPositiveIcon from '@creditas/brand-icons/dist/product/BProductCarPositive'
import BProductHomePositiveIcon from '@creditas/brand-icons/dist/product/BProductHomePositive'
import BProductPayrollPositiveIcon from '@creditas/brand-icons/dist/product/BProductPayrollPositive'

const autoRefi = {
  key: 'VehicleRefi',
  icon: BProductCarPositiveIcon,
  minLoanValue: '5',
  minLoanFullValue: '5000',
  minLoanValueString: '5 mil',
  maxLoanValue: '150',
  maxLoanFullValue: '150000',
  maxLoanValueString: '150000',
  minLoanInterest: '1,49',
  maximumYearCar: '2010',
  minLoanInterestYear: '20,80',
  term: {
    min: '18',
    max: '60',
  },
}

const homeRefi = {
  key: 'HomeRefi',
  icon: BProductHomePositiveIcon,
  minLoanValue: '50',
  minLoanFullValue: '50000',
  minLoanValueString: '50 mil',
  maxLoanValue: '3',
  maxLoanFullValue: '3000000',
  maxLoanValueString: '3 milhões',
  minLoanInterest: '0,89',
  minLoanInterestYear: '10,6',
  term: {
    min: '60',
    max: '240',
  },
}

const payroll = {
  key: 'Payroll',
  icon: BProductPayrollPositiveIcon,
  minLoanValue: '1',
  minLoanFullValue: '1000',
  minLoanValueString: '1 mil',
  maxLoanValue: '50',
  maxLoanFullValue: '50',
  maxLoanValueString: '50 mil',
  minLoanInterest: '0,99',
  minLoanInterestYear: '16,62',
  term: {
    min: '6',
    max: '48',
  },
}

const otherProducts = {
  key: 'otherProducts',
  overdraft: {
    minLoanInterestYear: '323',
  },
  personalLoan: {
    minLoanInterestYear: '120',
  },
  creditCard: {
    minLoanInterestYear: '300',
  },
}

const products = [autoRefi, homeRefi]
export { homeRefi, autoRefi, payroll, otherProducts, products }
