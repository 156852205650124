import React, { createContext, useMemo } from 'react'
import { node, string } from 'prop-types'

const TrackingEventsContext = createContext({
  eventCategory: '',
  eventAction: '',
})

TrackingEventsContext.displayName = 'TrackingEventsContext'

const propTypes = {
  action: string,
  category: string,
  children: node,
}

const TrackingEventsProvider = ({ action, category, children }) => {
  const contextValue = useMemo(
    () => ({
      eventCategory: category,
      eventAction: action,
    }),
    [category, action],
  )

  return (
    <TrackingEventsContext.Provider value={contextValue}>
      {children}
    </TrackingEventsContext.Provider>
  )
}

TrackingEventsProvider.propTypes = propTypes

export { TrackingEventsContext, TrackingEventsProvider }
