import React from 'react'

const PlayButton = ({ ...others }) => (
  <svg
    width="21"
    height="22"
    viewBox="0 0 21 22"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...others}
  >
    <path
      d="M20.0958 9.82195L2.92911 0.665944C2.72597 0.55748 2.4982 0.503478 2.26799 0.5092C2.03777 0.514921 1.81297 0.580171 1.61546 0.698592C1.41796 0.817013 1.25449 0.984569 1.14099 1.18494C1.02748 1.38531 0.96781 1.61166 0.967779 1.84194L0.967778 20.1526C0.967809 20.3829 1.02748 20.6092 1.14099 20.8096C1.25449 21.01 1.41796 21.1775 1.61546 21.296C1.81296 21.4144 2.03777 21.4796 2.26799 21.4854C2.4982 21.4911 2.72597 21.4371 2.92911 21.3286L20.0958 12.1739C20.3088 12.0601 20.4869 11.8906 20.611 11.6835C20.7352 11.4764 20.8008 11.2394 20.8008 10.9979C20.8008 10.7565 20.7352 10.5195 20.611 10.3124C20.4869 10.1053 20.3088 9.93575 20.0958 9.82195Z"
      fill="white"
    />
  </svg>
)

export { PlayButton }
