import React from 'react'

const PlayStore = ({ ...others }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...others}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.74791 16C1.814 15.978 1.88009 15.945 1.95719 15.901L11.6169 10.6227L9.32591 8.3354L1.74791 16ZM12.0355 10.3918L9.64533 8.01649L12.0355 5.60825L15.3178 7.40069C16.1219 7.84055 15.7143 8.39038 15.3178 8.61031L12.0355 10.3918ZM11.6059 5.36632L1.95719 0.109966C1.85806 0.0549828 1.7369 0.0219931 1.61574 0L9.3149 7.6756L11.6059 5.36632ZM1.054 0.0989692L8.99548 8.01649L1.08704 16C0.855735 15.857 0.734576 15.5381 0.734576 15.1313C0.734576 14.3505 0.668488 1.90241 0.668488 0.967698C0.657474 0.505842 0.822692 0.230928 1.054 0.0989692Z"
      fill="#1F2D27"
    />
  </svg>
)

export { PlayStore }
