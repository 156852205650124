import { useCallback, useEffect, useState } from 'react'
import { bool } from 'prop-types'

import { useLocalStorage } from 'hooks/useLocalStorage'
import { useTriggerEvents } from 'hooks/useTriggerEvents'

import { Banner, BANNER_TOP_POSITION } from './styles'

const propTypes = {
  hasSubMenu: bool,
}

const defaultProps = {
  hasSubMenu: false,
}

const AntiFraudBanner = ({ hasSubMenu }) => {
  const [showBanner, setShowBanner] = useState(false)
  const [isFixedOnTopScreen, setIsFixedOnTopScreen] = useState(false)
  const [antiFraudClosedAt, setAntiFraudTimestamp] = useLocalStorage(
    'antiFraudClosedAt',
    null,
  )
  const { triggerEvent } = useTriggerEvents()

  const text =
    'Atenção: Não pedimos pagamento antecipado para aprovação do empréstimo'

  const handleScroll = useCallback(() => {
    const newTopPosition = Math.round(window.pageYOffset)

    setIsFixedOnTopScreen(newTopPosition >= BANNER_TOP_POSITION)
  }, [])

  const closeBanner = () => {
    setAntiFraudTimestamp(Date.now())
    triggerEvent({
      label: `close | ${text}`,
      pageInteraction: true,
    })
  }

  useEffect(() => {
    const oneDayAgo = Date.now() - 60 * 60 * 24 * 1000
    const lastShowingExpired =
      !antiFraudClosedAt || antiFraudClosedAt <= oneDayAgo
    const shouldShowBanner = !showBanner && lastShowingExpired

    setTimeout(() => {
      if (shouldShowBanner) {
        setShowBanner(true)
      }
    }, 1500)
  }, [antiFraudClosedAt, showBanner])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  return showBanner ? (
    <Banner
      onClose={closeBanner}
      isFixedOnTopScreen={isFixedOnTopScreen}
      hasSubMenu={hasSubMenu}
      text={text}
      closable
    />
  ) : null
}

AntiFraudBanner.propTypes = propTypes
AntiFraudBanner.defaultProps = defaultProps

export { AntiFraudBanner }
