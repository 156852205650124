import { dispatchAnalyticsEvent } from 'services/dataLayer'

const isValidEvent = options => {
  return (
    'event' in options &&
    'category' in options &&
    'action' in options &&
    'label' in options &&
    'pageInteraction' in options &&
    'value' in options
  )
}

const dispatch = event => {
  if (!event) return

  dispatchAnalyticsEvent({
    category: event.eventCategory,
    action: event.eventAction,
    label: event.eventLabel,
  })
}

const triggerEventFromList = (events, label) => {
  const event = events.find(({ eventLabel }) => eventLabel === label)

  dispatch(event)
}

const triggerEvent = event => dispatch(event)

export { isValidEvent, triggerEvent, triggerEventFromList }
