import { css, styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

const spaceToHeader = headerType => {
  const headerSpaceTypes = {
    creditas: css`
      margin-top: 56px;

      ${media.up('5xl')} {
        margin-top: 80px;
      }
    `,
    product: css`
      margin-top: 112px;
    `,
  }

  return headerSpaceTypes[headerType] || headerSpaceTypes.product
}

const customReset = css`
  @font-face {
    font-family: 'Helvetica Now Display';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local('HelveticaNowDisplay Medium'),
      local('HelveticaNowDisplay-Medium'),
      url('https://assets.creditas.com/design-system-assets/fonts/1.0.0/helvetica-now-display/HelveticaNowDisplay-Medium.woff2')
        format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }

  html {
    scroll-behavior: smooth;
  }

  .header-menu-open {
    overflow: hidden;

    ${media.up('5xl')} {
      overflow: visible;
    }
  }
`

const Main = styled.main(
  ({ headerType, theme }) => css`
    ${spaceToHeader(headerType)}
    background-color: ${selectTheme('colors.neutral.0')({ theme })};
    // min-height to reduce layout shift
    min-height: 100vh;
    z-index: 2;

    b,
    strong {
      font-weight: ${selectTheme('fontWeight.lg')({ theme })};
    }

    i,
    em {
      font-style: italic;
    }

    u {
      text-decoration: underline;
    }
  `,
)

export { customReset, Main }
