import React from 'react'
import { string } from 'prop-types'

import { Typography } from '@creditas-ui/typography'

const propTypes = {
  children: string,
}

const RichText = ({ children, ...others }) => {
  if (!children) return null

  return (
    <Typography {...others} dangerouslySetInnerHTML={{ __html: children }} />
  )
}

RichText.propTypes = propTypes

export default RichText
