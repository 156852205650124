import React from 'react'

const Quotes = ({ ...others }) => (
  <svg
    width="49"
    height="40"
    viewBox="0 0 49 40"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...others}
  >
    <path
      d="M0 39.9837V28.9133C0 23.1176 0.700326 18.3639 2.10098 14.652C3.50163 10.9402 5.89577 7.78185 9.28339 5.19333C12.6547 2.58852 16.4658 0.862841 20.684 0V8.85633C17.6873 10.582 15.9772 12.8938 15.57 15.7916H20.684V39.9837H0ZM27.6384 39.9837V28.9133C27.6384 23.1176 28.3388 18.3639 29.7394 14.6357C31.1401 10.9239 33.5179 7.76557 36.873 5.17705C40.228 2.58852 44.0554 0.862841 48.3225 0V8.85633C45.3257 10.582 43.6156 12.8938 43.2085 15.8079H48.3225V40H27.6384V39.9837Z"
      fill="#C7CEC9"
    />
  </svg>
)

export { Quotes }
