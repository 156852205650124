import React from 'react'
import { arrayOf, oneOf, shape, string } from 'prop-types'
import { Helmet } from 'react-helmet'

const propTypes = {
  links: arrayOf(
    shape({
      href: string,
      hreflang: string,
      domain: oneOf(['https://www.creditas.com/', 'https://www.creditas.mx/']),
    }),
  ),
}

const AlternateLinks = ({ links }) =>
  links ? (
    <Helmet>
      {links.map(({ domain, href, hreflang }, key) => {
        const currentDomain = `${
          domain ?? process.env.PORTAL_DOMAIN_NAME
        }${href}`
        return (
          <link
            key={key}
            rel="alternate"
            href={currentDomain}
            hrefLang={hreflang}
          />
        )
      })}
    </Helmet>
  ) : (
    ''
  )

AlternateLinks.propTypes = propTypes

export { AlternateLinks }
