const subMenuOptions = [
  {
    id: 'financiamento-de-carros',
    path: '/financiamento-veiculos/',
  },
  {
    id: 'loja-de-carros',
    path: '/carro/loja/',
  },
  {
    id: 'loja-de-imoveis',
    path: '/imovel/loja/',
  },
  {
    id: 'reforma-de-imoveis',
    path: '/imovel/reformar/',
  },
  {
    id: 'venda-de-imoveis',
    path: '/imovel/vender/',
  },
  {
    id: 'troca-de-imoveis',
    path: '/imovel/trocar/',
  },
  {
    id: 'store-apple',
    path: '/store/apple/',
  },
  {
    id: 'store-smartphones',
    path: '/store/45/',
  },
  {
    id: 'store-eletrodomesticos',
    path: '/store/54/',
  },
  {
    id: 'emprestimo-consignado',
    path: '/emprestimo/consignado-privado',
  },
  {
    id: 'business-store',
    path: '/store',
  },
  {
    id: 'antecipacao-de-salario',
    path: '/beneficios-corporativos/antecipacao-salario/',
  },
  {
    id: 'cartao-de-beneficios',
    path: '/beneficios/cartao/',
  },
  {
    id: 'previdencia-privada',
    path: '/previdencia-privada/',
  },
]

export { subMenuOptions }
