import React from 'react'
import { object } from 'prop-types'
import { Helmet } from 'react-helmet'

import { organization } from 'constants/structuredData'

const propTypes = {
  pageData: object,
  siteData: object,
}

const StructuredData = ({ pageData, siteData }) => {
  const { legalName, name, url } = siteData
  const organizationData = {
    name,
    legalName,
    url,
    ...organization,
  }

  let data
  if (pageData) {
    data = pageData.hasOwnProperty('internal')
      ? pageData.internal.content
      : JSON.stringify(pageData)
  }

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(organizationData)}
      </script>
      {data && <script type="application/ld+json">{data}</script>}
    </Helmet>
  )
}

StructuredData.propTypes = propTypes

export { StructuredData }
