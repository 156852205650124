import { styled } from '@creditas-ui/system'

import { Container as CreditasContainer } from 'components/Container'

export const Container = styled(CreditasContainer)`
  position: relative;
  padding-top: 4px;
  padding-bottom: 4px;
  z-index: 2;
`

export const List = styled.ol`
  background-color: transparent;
  width: 100%;
  display: flex;
  list-style: none;
`
