import { autoRefi, homeRefi } from 'constants/products'
import { links } from 'constants/socialMedia'

const organization = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  logo:
    'https://assets.creditas.com/website-assets/images/1.0.0/logo-horizontal.svg',
  foundingDate: '2012',

  founder: {
    '@type': 'Person',
    name: 'Sergio Furio',
    sameAs: ['https://www.linkedin.com/in/sergiofurio'],
  },

  address: {
    '@type': 'PostalAddress',
    streetAddress: 'Avenida Engenheiro Luís Carlos Berrini, 105, 11º andar',
    addressLocality: 'São Paulo',
    addressRegion: 'SP',
    postalCode: '04571-010',
    addressCountry: 'BR',
  },

  contactPoint: {
    '@type': 'ContactPoint',
    contactType: 'customer service',
    telephone: '+55-11-3522-8009',
    email: 'meajuda@creditas.com.br',
  },

  sameAs: [
    links.facebook,
    links.instagram,
    links.linkedin,
    links.twitter,
    links.youtube,
  ],
}

const onlineLoan = {
  '@context': 'http://schema.org',
  '@type': 'LoanOrCredit',
  name: 'Empréstimo online com garantia',
  amount: [
    {
      '@type': 'MonetaryAmount',
      name: 'Empréstimo online com garantia de imóvel',
      minValue: homeRefi.minLoanFullValue,
      maxValue: homeRefi.maxLoanFullValue,
      currency: 'BRL',
    },
    {
      '@type': 'MonetaryAmount',
      name: 'Empréstimo online com garantia de veículo',
      minValue: autoRefi.minLoanFullValue,
      maxValue: autoRefi.maxLoanFullValue,
      currency: 'BRL',
    },
  ],
  loanTerm: [
    {
      '@type': 'QuantitativeValue',
      minValue: homeRefi.term.min,
      maxValue: homeRefi.term.max,
      unitCode: 'MON',
    },
    {
      '@type': 'QuantitativeValue',
      minValue: autoRefi.term.min,
      maxValue: autoRefi.term.max,
      unitCode: 'MON',
    },
  ],
  renegotiableLoan: false,
  requiredCollateral: ['house or apartment', 'car'],
  annualPercentageRate: [
    homeRefi.minLoanInterestYear.replace(',', '.'),
    autoRefi.minLoanInterestYear.replace(',', '.'),
  ],
  interestRate: [
    homeRefi.minLoanInterest.replace(',', '.'),
    autoRefi.minLoanInterest.replace(',', '.'),
  ],
}

export { organization, onlineLoan }
