import React, { useEffect, useState } from 'react'
import { func, oneOf, string } from 'prop-types'

import { HeaderMenu } from '@creditas-lab/header-menu'

import { TrackingEventsProvider } from 'contexts/TrackingEventsContext'

import { AntiFraudBanner } from './components/AntiFraudBanner'
import { getSubMenuOptionId } from './utils/getSubMenuOptionId'

import { Wrapper } from './styles'

const VALID_HEADER_TYPES = ['auto', 'home', 'store', 'business', 'creditas']

const propTypes = {
  'data-testid': string,
  setIsOpen: func,
  type: oneOf(VALID_HEADER_TYPES),
}

const defaultProps = {
  'data-testid': 'header',
  type: 'creditas',
}

const Header = ({ 'data-testid': dataTestId, setIsOpen, type, ...others }) => {
  const currentType = VALID_HEADER_TYPES.find(validType => {
    return validType === type
  })
  const [headerActiveItem, setHeaderActiveItem] = useState('')
  const validHeaderType = currentType || defaultProps.type

  const handleToggleCallback = state => {
    if (setIsOpen) {
      setIsOpen(state)
    }
  }

  useEffect(() => {
    setHeaderActiveItem(getSubMenuOptionId(window.location.pathname))
  }, [setHeaderActiveItem])

  return (
    <Wrapper data-testid={dataTestId} {...others}>
      <HeaderMenu
        type={validHeaderType}
        data-testid={`${dataTestId}__${validHeaderType}`}
        activeItem={headerActiveItem}
        toggleCallback={handleToggleCallback}
      />
      <TrackingEventsProvider
        action="Banner | Antifraude"
        category="Creditas | Multiprodutos"
      >
        <AntiFraudBanner hasSubMenu={validHeaderType !== 'creditas'} />
      </TrackingEventsProvider>
    </Wrapper>
  )
}

Header.propTypes = propTypes
Header.defaultProps = defaultProps
Header.displayName = 'Header'

export { Header }
