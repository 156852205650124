import { css, styled } from '@creditas-ui/system'
import { media } from '@creditas-ui/utilities'

import { Banner as WebsiteBanner } from 'components/Banner'

const BANNER_TOP_POSITION = 80
const BANNER_TOP_POSITION_SUBMENU = 112

const Banner = styled(WebsiteBanner)(
  ({ hasSubMenu, isFixedOnTopScreen }) => css`
    position: fixed;
    bottom: 0;
    z-index: -1;

    ${media.up('5xl')} {
      position: ${isFixedOnTopScreen ? 'fixed' : 'absolute'};
      top: ${hasSubMenu ? BANNER_TOP_POSITION_SUBMENU : BANNER_TOP_POSITION}px;
      bottom: auto;

      ${hasSubMenu &&
      isFixedOnTopScreen &&
      css`
        margin-top: -48px;
      `}
    }
  `,
)

export { Banner, BANNER_TOP_POSITION }
