import React from 'react'
import { array, string } from 'prop-types'

import { TrackingEventsProvider } from 'contexts/TrackingEventsContext'

import Sections from 'sections'

import { Breadcrumb } from 'components/Breadcrumb'
import { GreenFridayBanner } from 'components/GreenFridayBanner'

const propTypes = {
  breadcrumbs: array,
  contents: array,
  customBannerTextByPath: array,
  features: array,
  ignoreBannerPaths: array,
  path: string,
  product: string,
}

const defaultPropTypes = {
  contents: [],
  features: [],
  ignoreBannerPaths: [
    '/',
    'emprestimo/garantia-veiculo',
    'emprestimo/garantia-imovel',
    'green-friday',
    'emprestimo-online',
  ],
  customBannerTextByPath: [
    {
      text: '',
      path: '',
    },
    {
      text: '',
      path: '',
    },
  ],
}

const Content = props => {
  const {
    breadcrumbs,
    contents,
    customBannerTextByPath,
    features,
    ignoreBannerPaths,
    path,
    product,
  } = props

  return (
    <>
      {breadcrumbs?.length && <Breadcrumb levels={breadcrumbs} />}

      {contents?.map((content, index) => {
        if (!content?.__typename) return null

        const sectionName = content.__typename.replace('ContentfulSection', '')
        const Section = Sections[sectionName]
        const variant = content?.variant || content?.template
        const eventAction = variant
          ? `${sectionName} | ${variant}`
          : sectionName

        return (
          Section && (
            <TrackingEventsProvider
              action={eventAction}
              category={product}
              key={index}
            >
              <Section {...content} id={`${sectionName}-${index}`} />
              <GreenFridayBanner
                currentPath={path}
                customBannerTextByPath={customBannerTextByPath}
                ignoreBannerPaths={ignoreBannerPaths}
                shouldRender={features?.includes('green-friday-banner')}
                sectionIndex={index}
              />
            </TrackingEventsProvider>
          )
        )
      })}
    </>
  )
}

Content.propTypes = propTypes
Content.defaultPropTypes = defaultPropTypes

export { Content }
