import React from 'react'

const ArrowDownIcon = ({ ...others }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...others}
  >
    <path
      d="M28 10L16 22L4 10"
      stroke="#1F2D27"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export { ArrowDownIcon }
