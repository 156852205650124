import { useCallback, useEffect, useState } from 'react'
import { bool, func, string } from 'prop-types'

import { CloseIcon } from 'components/Icons'

import {
  AnimateWrapper,
  animationVariants,
  Container,
  IconWrapper,
  MotionWrapper,
  TextWrapper,
} from './styles'

const propTypes = {
  closable: bool,
  'data-testid': string,
  icon: func,
  onClose: func,
  text: string,
}

const defaultProps = {
  'data-testid': 'component-banner',
  closable: false,
}

const Banner = ({
  closable,
  'data-testid': dataTestid,
  icon,
  onClose,
  text,
  ...others
}) => {
  const [show, setShow] = useState(true)
  const Icon = closable ? CloseIcon : icon ?? null
  const isMobile = window.innerWidth < 1024

  const handleClick = useCallback(() => {
    if (closable) {
      onClose?.()
      setShow(false)
    }
  }, [onClose, closable])

  useEffect(() => {
    let timer
    if (show && isMobile) {
      timer = setTimeout(() => setShow(false), 15000)
    }

    return () => clearTimeout(timer)
  }, [isMobile, show])

  return (
    <AnimateWrapper>
      {show && text && (
        <MotionWrapper
          key="banner"
          data-testid={dataTestid}
          onClick={handleClick}
          variants={animationVariants[isMobile ? 'mobile' : 'desktop']}
          initial="initial"
          animate="animate"
          exit="exit"
          {...others}
        >
          <Container>
            <TextWrapper>{text}</TextWrapper>
            {Icon && (
              <IconWrapper>
                <Icon data-testid={`${dataTestid}-icon`} />
              </IconWrapper>
            )}
          </Container>
        </MotionWrapper>
      )}
    </AnimateWrapper>
  )
}

Banner.propTypes = propTypes
Banner.defaultProps = defaultProps

export { Banner }
