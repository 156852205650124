import React from 'react'
import { object } from 'prop-types'
import { graphql } from 'gatsby'

import { parse } from 'query-string'
import { Content } from 'ui/Layout/Content'
import { Layout } from 'ui/Layout/Layout'
import { windowGlobal } from 'ui/utils/windowGlobal'

const propTypes = {
  data: object,
}

const Landing = ({ data }) => {
  const { contentfulFeatureToggle, contentfulLanding } = data
  const { header, metatags, path } = contentfulLanding
  const queryParams = parse(windowGlobal()?.location?.search)

  return (
    <Layout
      metatags={{ ...metatags, path }}
      header={header}
      experiment={queryParams?.experiment}
    >
      <Content {...contentfulLanding} {...contentfulFeatureToggle} />
    </Layout>
  )
}

Landing.propTypes = propTypes

export const query = graphql`
  query LandingBySlug($slug: String!) {
    contentfulFeatureToggle {
      ignoreBannerPaths
      features
      customBannerTextByPath {
        text
        path
      }
    }
    contentfulLanding(path: { eq: $slug }) {
      title
      path
      metatags {
        title
        noindex
        canonical
        description
        alternateLinks {
          href
          hreflang
          domain
        }
        structuredData: childContentfulSeoMetatagsStructuredDataJsonNode {
          internal {
            content
          }
        }
        ogImage {
          ...ContentfulImage
        }
      }
      breadcrumbs {
        id
        href
        label
      }
      header
      product
      contents {
        ... on Node {
          __typename

          ... on ContentfulSectionHeroInfluencer {
            ...SectionHeroInfluencer
          }

          ... on ContentfulSectionHeroWithSlider {
            ...SectionHeroWithSlider
          }

          ... on ContentfulSectionProductComparison {
            ...SectionProductComparison
          }

          ... on ContentfulSectionExponencial {
            ...SectionExponencial
          }

          ... on ContentfulSectionSocialProof {
            ...SectionSocialProof
          }

          ... on ContentfulSectionGenericImage {
            ...SectionGenericImage
          }

          ... on ContentfulSectionGenericVideo {
            ...SectionGenericVideo
          }

          ... on ContentfulSectionHowItWorks {
            ...SectionHowItWorks
          }

          ... on ContentfulSectionRelatedLinks {
            ...SectionRelatedLinks
          }

          ... on ContentfulSectionHeroStepper {
            ...SectionHeroStepper
          }

          ... on ContentfulSectionHeroProducts {
            ...SectionHeroProducts
          }

          ... on ContentfulSectionCrossSell {
            ...SectionCrossSell
          }

          ... on ContentfulSectionHeroForm {
            ...SectionHeroForm
          }

          ... on ContentfulSectionHeroFormWithImage {
            ...SectionHeroFormWithImage
          }

          ... on ContentfulSectionFullWidthImage {
            ...SectionFullWidthImage
          }

          ... on ContentfulSectionPureText {
            ...SectionPureText
          }

          ... on ContentfulSectionCpfChecker {
            ...SectionCpfChecker
          }

          ... on ContentfulSectionMediaLogos {
            ...SectionMediaLogos
          }

          ... on ContentfulSectionGenericSlides {
            ...SectionGenericSlides
          }

          ... on ContentfulSectionFaq {
            ...SectionFaq
          }

          ... on ContentfulSectionWhyTrustUs {
            ...SectionWhyTrustUs
          }

          ... on ContentfulSectionHeroImage {
            ...SectionHeroImage
          }

          ... on ContentfulSectionPersonalizedService {
            ...SectionPersonalizedService
          }

          ... on ContentfulSectionQa {
            ...SectionQa
          }

          ... on ContentfulSectionWhyChooseUsChart {
            ...SectionWhyChooseUsChart
          }

          ... on ContentfulSectionExponencialBanner {
            ...SectionExponencialBanner
          }

          ... on ContentfulSectionCarouselImageVideo {
            ...SectionCarouselImageVideo
          }

          ... on ContentfulSectionSpacing {
            ...SectionSpacing
          }

          ... on ContentfulSectionBenefits {
            ...SectionBenefits
          }

          ... on ContentfulSectionHighlightImage {
            ...SectionHighlightImage
          }

          ... on ContentfulSectionHeroHighlightImageWithForm {
            ...SectionHeroHighlightImageWithForm
          }
        }
      }
    }
  }
`

export default Landing
