import React from 'react'

const QuestionCircleIcon = ({ ...others }) => (
  <svg
    width="40"
    height="40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...others}
  >
    <path
      d="M20 22.007v-.4c0-1.312.81-2.022 1.623-2.567.794-.533 1.589-1.23 1.589-2.515a3.21 3.21 0 00-3.21-3.21 3.208 3.208 0 00-3.212 3.207M19.998 26.532a.291.291 0 00-.282.347.29.29 0 10.282-.347"
      stroke="#1B7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.427 25.213l2.978 1.489A14.909 14.909 0 0035 20 15 15 0 0020 5a14.897 14.897 0 00-9.633 3.517M9.573 14.787l-2.978-1.49A14.922 14.922 0 005 20a15 15 0 0015 15 14.897 14.897 0 009.633-3.517"
      stroke="#1B7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export { QuestionCircleIcon }
