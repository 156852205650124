export const typographyColors = {
  bHeadingh1Color: '#334444',
  bHeadingh1FallbackColor: '#334444',
  bHeadingh1SystemColor: '#334444',
  bHeadingh2Color: '#334444',
  bHeadingh2FallbackColor: '#334444',
  bHeadingh2SystemColor: '#334444',
  bHeadingh3Color: '#334444',
  bHeadingh3FallbackColor: '#334444',
  bHeadingh3SystemColor: '#334444',
  bHeadingh4Color: '#334444',
  bHeadingh4FallbackColor: '#334444',
  bHeadingh4SystemColor: '#334444',
  bHeadingh5Color: '#334444',
  bHeadingh5FallbackColor: '#334444',
  bHeadingh5SystemColor: '#334444',
  bHeadingh6Color: '#334444',
  bHeadingh6FallbackColor: '#334444',
  bHeadingh6SystemColor: '#334444',
  bLabalcaptionFallbackColor: '#556666',
  bLabelactionColor: '#556666',
  bLabelactionFallbackColor: '#556666',
  bLabelbuttonColor: '#556666',
  bLabelbuttonFallbackColor: '#556666',
  bLabelcaptionColor: '#556666',
  bLabelformColor: '#556666',
  bLabelformFallbackColor: '#556666',
  bTextparagraphColor: '#556666',
  bTextparagraphFallbackColor: '#556666',
  bTextparagraphSystemColor: '#556666',
  bTextsupportColor: '#556666',
  bTextsupportFallbackColor: '#556666',
  bTextsupportSystemColor: '#556666',
  bTextsupportformColor: '#556666',
  bTextsupportformFallbackColor: '#556666',
}

export const typographyFonts = {
  bHeadingh1FontFamily: '"Helvetica Now Display", "HelveticaNowDisplay-Bold"',
  bHeadingh1FontWeight: '700',
  bHeadingh2FontFamily: '"Helvetica Now Display", "HelveticaNowDisplay-Bold"',
  bHeadingh2FontWeight: '700',
  bHeadingh3FontFamily: '"Helvetica Now Display", "HelveticaNowDisplay-Bold"',
  bHeadingh3FontWeight: '700',
  bHeadingh4FontFamily: '"Helvetica Now Display", "HelveticaNowDisplay-Bold"',
  bHeadingh4FontWeight: '700',
  bHeadingh5FontFamily: '"Helvetica Now Display", "HelveticaNowDisplay-Bold"',
  bHeadingh5FontWeight: '700',
  bHeadingh6FontFamily: '"Helvetica Now Display", "HelveticaNowDisplay-Bold"',
  bHeadingh6FontWeight: '700',
  bTextparagraphFontFamily:
    '"Helvetica Now Display", "HelveticaNowDisplay-Medium"',
  bTextparagraphFontWeight: '400',
  bTextsupportFontFamily:
    '"Helvetica Now Display", "HelveticaNowDisplay-Medium"',
  bTextsupportFontWeight: '400',
  bTextsupportformFontFamily:
    '"Helvetica Now Display", "HelveticaNowDisplay-Medium"',
  bTextsupportformFontWeight: '400',
  bLabelformFontFamily: '"Helvetica Now Display", "HelveticaNowDisplay-Bold"',
  bLabelformFontWeight: '700',
  bLabelbuttonFontFamily: '"Helvetica Now Display", "HelveticaNowDisplay-Bold"',
  bLabelbuttonFontWeight: '700',
  bLabelactionFontFamily: '"Helvetica Now Display", "HelveticaNowDisplay-Bold"',
  bLabelactionFontWeight: '700',
}
