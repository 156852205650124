import React from 'react'

const ArrowRightIcon = ({ ...others }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...others}
  >
    <path
      stroke="currentColor"
      d="M25.3346 16H6.66797"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      stroke="currentColor"
      d="M18.668 22.6667L25.3346 16"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      stroke="currentColor"
      d="M18.668 9.33301L25.3346 15.9997"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export { ArrowRightIcon }
