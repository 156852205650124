import React from 'react'
import { arrayOf, bool, object, oneOf, shape, string } from 'prop-types'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'

import PreviewImage from 'images/preview.jpg'

import { AlternateLinks } from './AlternateLinks'
import { Facebook } from './Facebook'
import { StructuredData } from './StructuredData'
import { Twitter } from './Twitter'

const propTypes = {
  data: shape({
    title: string.isRequired,
    path: string.isRequired,
    description: string,
    structuredData: object,
    noindex: bool,
    alternateLinks: arrayOf(
      shape({
        href: string,
        hreflang: string,
        domain: oneOf([
          'https://www.creditas.com/',
          'https://www.creditas.mx/',
        ]),
      }),
    ),
    ogImage: object,
    canonical: string,
  }),
}

const query = graphql`
  query GetSiteData {
    site {
      siteMetadata {
        url
        name
        legalName
      }
    }
  }
`

const Metatags = ({ data }) => {
  const {
    alternateLinks,
    canonical,
    description,
    noindex,
    ogImage,
    path,
    structuredData,
    title,
  } = data

  const { site } = useStaticQuery(query)
  const { siteMetadata } = site
  const standardizedPath = path.charAt(0) === '/' ? path : `/${path}`
  const absoluteUrl = `${process.env.PORTAL_DOMAIN_NAME}${standardizedPath}`
  const defaultPreviewImage = `${process.env.PORTAL_DOMAIN_NAME}${PreviewImage}`
  const image = ogImage?.fluid?.src
    ? `https:${ogImage.fluid.src}`
    : defaultPreviewImage

  return (
    <>
      <Helmet title={title}>
        <html lang="pt-BR" />
        <meta name="format-detection" content="telephone=no" />
        <meta
          httpEquiv="Content-Security-Policy"
          content="upgrade-insecure-requests"
        />
        <meta
          name="p:domain_verify"
          content="8fbe9b48d83805b9370ab0d0c2d6b698"
        />
        {description && <meta name="description" content={description} />}
        {noindex && <meta name="robots" content="noindex" />}
        {canonical && (
          <link
            rel="canonical"
            href={`${process.env.PORTAL_DOMAIN_NAME}${canonical}`}
          />
        )}
      </Helmet>

      <>
        <StructuredData siteData={siteMetadata} pageData={structuredData} />
        <AlternateLinks links={alternateLinks} />
        <Facebook
          title={title}
          description={description}
          url={absoluteUrl}
          siteName={siteMetadata.name}
          image={image}
        />
        <Twitter
          title={title}
          description={description}
          url={absoluteUrl}
          siteName={siteMetadata.name}
          image={image}
        />
      </>
    </>
  )
}

Metatags.propTypes = propTypes

export { Metatags }
