export const legacy = {
  legacyColors: {
    neutral02: '#F8F8F8',
    neutral03: '#EEEEEE',
    neutral04: '#CCCCCC',
    neutral07: '#556666',
    neutral08: '#334444',
    neutral09: '#111515',
    primary05: '#005533',
    secondary04: '#6699DD',
    secondary05: '#3377bb',
    secondary06: '#005599',
    error60: '#DC3838',
  },
}
