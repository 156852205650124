import { useContext } from 'react'

import { dispatch } from 'services/dataLayer'
import { isValidEvent } from 'utils/analytics'

import { TrackingEventsContext } from 'contexts/TrackingEventsContext'

const useTriggerEvents = () => {
  const { eventAction, eventCategory } = useContext(TrackingEventsContext)

  const triggerEvent = ({ label, pageInteraction, ...others }) => {
    const event = {
      event: 'frontend_interaction_event',
      category: eventCategory,
      action: eventAction,
      value: 0,
      label,
      pageInteraction,
      ...others,
    }

    if (isValidEvent(event)) dispatch(event)
  }

  return { triggerEvent }
}

export { useTriggerEvents }
