import { styled } from '@creditas-ui/system'
import { getTypography, selectTheme } from '@creditas-ui/utilities'
import { AnimatePresence, motion } from 'framer-motion'

import { Container as CreditasContainer } from 'components/Container'

export const AnimateWrapper = styled(AnimatePresence)``

export const MotionWrapper = styled(motion.div)`
  padding: ${selectTheme('spacingY.5xl')} ${selectTheme('spacingX.5xl')};
  width: 100%;
  background-color: ${selectTheme('colors.neutral.90')};
  box-shadow: ${selectTheme('shadow.sm')};
  color: ${selectTheme('colors.neutral.0')};

  ${getTypography('bodySmRegular')}
`

export const Container = styled(CreditasContainer)`
  display: flex;
  align-items: center;
  vertical-align: center;
`

export const IconWrapper = styled.div`
  display: flex;
  margin-left: ${selectTheme('spacingLeft.6xl')};
`

export const TextWrapper = styled.div`
  flex-grow: 1;
`

export const animationVariants = {
  mobile: {
    initial: {
      y: '100%',
    },
    animate: {
      y: '0',
      transition: {
        duration: 0.6,
        ease: [0, 1, 0.5, 1],
      },
    },
    exit: {
      y: '100%',
      transition: {
        duration: 0.6,
        ease: [0, 1, 0.5, 1],
      },
    },
  },
  desktop: {
    initial: {
      y: '-100%',
    },
    animate: {
      y: '0',
      transition: {
        duration: 0.6,
        ease: [0, 1, 0.5, 1],
      },
    },
    exit: {
      y: '-100%',
      transition: {
        duration: 0.6,
        ease: [0, 1, 0.5, 1],
      },
    },
  },
}
