import { styled } from '@creditas/stylitas'

const Wrapper = styled.nav`
  cursor: pointer;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
`

export { Wrapper }
