const dispatch = options => {
  if (typeof options !== 'object' || typeof window === 'undefined') return

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(options)
}

const dispatchAnalyticsEvent = options => {
  if (typeof options !== 'object') return

  dispatch({
    event: 'gaCreditasEvent',
    ...options,
  })
}

const dispatchInboundLink = (action, label) => {
  dispatchAnalyticsEvent({
    category: 'Inbound Links',
    action,
    label,
  })
}

const dispatchInteraction = action => {
  dispatchAnalyticsEvent({
    category: 'Interaction',
    label: '',
    action,
  })
}

export {
  dispatch,
  dispatchAnalyticsEvent,
  dispatchInboundLink,
  dispatchInteraction,
}
